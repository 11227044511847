import Store from 'store'

const keys = {
  futuresFavorites: 'futures-favorites'
}

export default class FuturesStore {
  static getFuturesFavorites() {
    return Store.get(keys.futuresFavorites) || []
  }
  static delFuturesFavorites(id) {
    if (!id) return

    const list = this.getFuturesFavorites() || []
    const index = list.findIndex(item => item === id)
    if (index > -1) {
      list.splice(index, 1)
    }
    Store.set(keys.futuresFavorites, list)
  }
  static addFuturesFavorites(id) {
    this.delFuturesFavorites(id)
    const list = this.getFuturesFavorites() || []
    list.unshift(id)
    Store.set(keys.futuresFavorites, list)
  }
}