import Home from 'data/api/home';
import Status from 'data/immutable-data/Status';
// eslint-disable-next-line import/no-unresolved,import/extensions
import { article } from 'data/store/modules/article-zt';
import { exchange } from 'data/store/modules/exchange';
import { feedback } from 'data/store/modules/feedback';
import { market } from 'data/store/modules/market';
// eslint-disable-next-line import/no-unresolved,import/extensions
import { user } from 'data/store/modules/user-zt';
import { futures } from 'data/store/modules/futures';
import { header } from '../store/modules/header';
import { SYSTEM } from 'data/store/mutations-types';
import { orderBy } from 'utils/Sort';
import { getChainsConfig } from 'data/api/common'
import StoreToStorage from 'utils/StoreToStorage';
import Vue from 'vue';
import Vuex from 'vuex';
import Singleton from 'utils/Singleton';

Vue.use(Vuex);

function generateFooters() {
  return Object.keys(Status.footerItems).reduce((pre, key) => {
    // eslint-disable-next-line no-param-reassign
    pre[Status.footerItems[key].alias] = [];
    return pre;
  }, {});
}

const store = new Vuex.Store({
  state: {
    siteInfo: {},
    banners: [],
    popup: {},
    contacts: [],
    footers: StoreToStorage.getFooter() || generateFooters(),
    // 服务器与客户端本地时间差
    deltaTime: 0,
    theme: StoreToStorage.getTheme() || 'dark',
    isApp: false,
    localeCurrencyModalShow: false,
    chainsConfig: {}
  },
  getters: {
    siteInfo({ siteInfo = {} }) {
      const { logo, icon } = siteInfo;
      const privilages = Reflect.has(siteInfo, 'privilages') ? siteInfo.privilages : '4,8';
      const arr = privilages.split(',');
      return {
        ...siteInfo,
        logo: logo,
        // c2cEnabled: arr.includes('4'),
        c2cEnabled: false,
        otcEnabled: arr.includes('8'),
      };
    },
    otcAssets({ siteInfo = {} }) {
      const { otc_asset = '', otc_currency = '' } = siteInfo;
      return [...new Set(otc_asset.split(',').concat(otc_currency.split(',')))];
    },
  },
  mutations: {
    setIsApp(_state, bol) {
      _state.isApp = bol;
    },
    setTheme(_state, theme) {
      StoreToStorage.setTheme(theme)
      _state.theme = theme;
    },
    switchTheme(_state) {
      const theme = _state.theme === 'dark' ? 'light' : 'dark';
      _state.theme =theme
      StoreToStorage.setTheme(theme)
    },
    setLocaleCurrencyModalShow(_state, bol) {
      _state.localeCurrencyModalShow = bol
    },
    [SYSTEM.GET_BANNERS](_state, arr) {
      Vue.set(_state, 'banners', arr);
    },
    [SYSTEM.GET_POPUP](_state, img) {
      Vue.set(_state, 'popup', img || {});
    },
    [SYSTEM.GET_CONTACTS](_state, arr) {
      Vue.set(_state, 'contacts', arr || []);
    },
    [SYSTEM.GET_HEADERS](_state, arr) {
      Vue.set(_state, 'headers', arr || []);
      StoreToStorage.setHeader(_state.headers);
    },
    [SYSTEM.GET_FOOTERS](_state, obj) {
      Vue.set(_state, 'footers', obj || {});
      StoreToStorage.setFooter(_state.footers);
    },
    [SYSTEM.GET_DELTA_TIME](_state, deltaTime) {
      Vue.set(_state, 'deltaTime', deltaTime);
    },
    [SYSTEM.GET_SITE_INFO](_state, obj) {
      Vue.set(_state, 'siteInfo', obj);
    },
    setChainsConfig(_state, list) {
      _state.chainsConfig = {}
      
      list.forEach(item => {
        _state.chainsConfig[item.chain_name] = item
      })
    }
  },
  actions: {
    getBanners({ commit, state }, position) {
      return state.banners.length > 0
        ? Promise.resolve()
        : Home.getBanner(position).then((res) => {
            const banners = orderBy(
              res.filter((img) => img.type === 1),
              'display_order',
              'desc',
              true,
            );
            commit(SYSTEM.GET_BANNERS, banners.length > 0 ? banners : []);
            commit(
              SYSTEM.GET_POPUP,
              res.find((img) => img.type === 2 && img.display_order !== 0),
            );
            return res;
          });
    },
    getContacts({ commit }) {
      return Home.getContacts().then((res) => {
        commit(SYSTEM.GET_CONTACTS, res);
        return res;
      });
    },
    getFooters({ commit, dispatch }) {
      return Promise.all([
        Home.getFooters(),
        dispatch('article/getArticleClass', 'help'),
        Home.getContacts(),
      ]).then(([footers, articleClass, followUs]) => {
        const obj = generateFooters();
        footers = footers.map((item) => ({ ...item, class_id: item.class_id || 1 }));
        articleClass = articleClass.map((item) => ({ ...item, class_id: 2 }));
        followUs = followUs.map((item) => ({ ...item, class_id: 3 }));

        [...footers, ...articleClass, ...followUs].forEach((item) => {
          const type = Status.footerItems[item.class_id];
          if (type) obj[type.alias].push(item);
        });
        
        commit(SYSTEM.GET_FOOTERS, obj);
        return footers;
      });
    },
    getDeltaTime({ commit, state }) {
      return state.deltaTime
        ? Promise.resolve(state.deltaTime)
        : Home.getDeltaTime().then((deltaT) => {
            commit(SYSTEM.GET_DELTA_TIME, deltaT);
            return deltaT;
          });
    },
    getSiteInfo({ commit }) {
      return Singleton.promise(function() {
        return Home.getSiteInfo().then((res) => {
          commit(SYSTEM.GET_SITE_INFO, res);
          return res;
        })
      }, 'getSiteInfo');
    },
    getChainsConfig({ commit }) {
      return getChainsConfig().then(res => {
        commit('setChainsConfig', res || [])
        return res
      })
    }
  },
  modules: {
    user,
    exchange,
    market,
    article,
    feedback,
    futures,
    header,
  },
});

export default store;
