// import 'babel-polyfill'
import { initialExtensions } from '@/extensions/extensions';
import store from 'data/store';
import Vue from 'vue';
import App from './App';
// eslint-disable-next-line import/no-unresolved,import/extensions
import register from './global-register';
// 根据 mask 使用不同的路由配置
// eslint-disable-next-line import/no-unresolved,import/extensions
import { createRouter } from './router';
import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false;

// 注册 Http, Cache, bus, 资产折合等服务
const { i18n } = initialExtensions();

// 注册全局函数，属性，组件，错误处理函数
register();
// 若是没有开启Devtools工具，在开发环境中开启，在生产环境中关闭
if (process.env.NODE_ENV == 'development') {
  Vue.config.devtools = true;
} else {
  Vue.config.devtools = false;
}
// 创建路由
/* eslint-disable no-new */
const router = createRouter(i18n, store);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: "https://d6e3a1456e9149a880ba3bfe4dc91dc3@o4505509832556544.ingest.sentry.io/4505509855625216",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/biconomy\.com\/api/],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

// 创建 Vue 根实例，注入 router，store，i18n
const root = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
});

// window.onload = () => {
//   // prod: webpack HtmlWebpackPlugin 配置 {inject: 'head',chunksSortMode: 'dependency'}，
//   // 使得js代码被插入到了 head 标签，先于 body DOM 生成之前运行，因此使用 DOMContentLoaded 事件处理
//   if (typeof window !== 'undefined') {
//     store.replaceState({ ...store.state, ...window.INIT_STATE })
//   }
//   root.$mount('#app')
// }
document.addEventListener('DOMContentLoaded', () => {
  // prod: webpack HtmlWebpackPlugin 配置 {inject: 'head',chunksSortMode: 'dependency'}，
  // 使得js代码被插入到了 head 标签，先于 body DOM 生成之前运行，因此使用 DOMContentLoaded 事件处理
  if (typeof window !== 'undefined') {
    store.replaceState({ ...store.state, ...window.INIT_STATE });
  }
  root.$mount('#app');
});
