import { bigNum, numTrim } from 'utils/big.js'

export function getBuyPl(data, lastPrice) {
  if (Number(lastPrice) === 0) return 0;
  let buyPL = 0;
  
  if (data.usdtBuyPrice > 0) {
    const position = bigNum(data.usdtBuyPosition).plus(data.usdtFrozenBuyPosition)

    buyPL = bigNum(lastPrice)
      .div(data.usdtBuyPrice)
      .minus(1)
      .times(position)
      .times(data.usdtShareNumber)
      .toFixed()
  }

  return buyPL;
}

export function getSellPl(data, lastPrice) {
  if (Number(lastPrice) === 0) return 0;
  let sellPL = 0;
  
  if (data.usdtSellPrice > 0) {
    const position = bigNum(data.usdtSellPosition).plus(data.usdtFrozenSellPosition)

    sellPL = bigNum(1)
      .minus(bigNum(lastPrice).div(data.usdtSellPrice))
      .times(position)
      .times(data.usdtShareNumber)
      .toFixed()
  }

  return sellPL;
}

export function getCloseFee({ position, frozenPosition, shareNumber, closeFee }) {
  return (position + frozenPosition) * shareNumber * closeFee;
}