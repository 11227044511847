import { get, postForm, post } from 'utils/axios/futures-axios';

export function getSymbols() {
  return get('/swap/symbol-thumb');
}

export function getWalletList() {
  return post('/swap/wallet/list');
}

export function getKlineHistory({ symbol, from, to, resolution }) {
  return postForm('/swap/history', { symbol, from, to, resolution });
}
