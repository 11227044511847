<template>
  <div class="container" :class="theme">
    <template v-if="!isApp">
      <ClientHeader :show="showHeader" v-show="showHeader" />
    </template>

    <router-view />

    <template v-if="!isApp">
      <Footer />
      <MobileFooter />
    </template>
  </div>
</template>
<script>
/* eslint-disable import/extensions,import/no-unresolved */
import ClientHeader from 'components/client/header/header';
import Footer from 'components/client/footer/index.vue';
import MobileFooter from 'components/client/MobileFooter';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Frame',
  components: {
    Footer,
    ClientHeader,
    MobileFooter
  },
  computed: {
    ...mapState(['isApp']),
    dark() {
      return this.$route.path === '/';
    },
    showHeader() {
      return !['/nft/withdraw'].includes(this.$route.path);
    }
  }
};
</script>
