export const telegramList = [
  {
    name: 'Global Telegram',
    link: 'https://t.me/Biconomycom',
    code: 'global',
  },
  {
    name: 'French Telegram',
    link: 'https://t.me/+XBAHb6NyFSJjNTZl',
    code: 'fr',
  },
  {
    name: 'Vietnamese Telegram',
    link: 'https://t.me/Biconomyvietnamese',
    code: 'vn',
  },
  {
    name: 'Russian Telegram',
    link: 'https://t.me/biconomyrus',
    code: 'ru',
  },
  {
    name: 'Spanish Telegram',
    link: 'https://t.me/Biconomyspanish',
    code: 'es',
  },
  {
    name: 'Germany Telegram',
    link: 'https://t.me/biconomygermany',
    code: 'de',
  },
  {
    name: 'Korean Telegram',
    link: 'https://t.me/biconomykorean',
    code: 'kr',
  },
  {
    name: 'Portuguese Telegram',
    link: 'https://t.me/biconomy_portuguese',
    code: 'pt',
  },
  {
    name: 'Indian Telegram',
    link: 'https://t.me/biconomyhindi',
    code: 'in',
  },
  {
    name: 'Chinese Telegram',
    link: 'https://t.me/biconomybittoken',
    code: 'cn',
  },
  {
    name: 'Indonesia Telegram',
    link: 'https://t.me/biconomyindonesia',
    code: 'id',
  },
  {
    name: 'Nigerian Telegram',
    link: 'https://t.me/BiconomyNigeria',
    code: 'ng',
  },
  {
    name: 'Turkey Telegram',
    link: 'https://t.me/biconomyturkeyy',
    code: 'tr',
  },
  {
    name: 'Arab Telegram',
    link: 'https://t.me/biconomyarab',
    code: 'ae',
  },
  {
    name: 'Malaysia Telegram',
    link: 'https://t.me/joinchat/26ffm4rr4iY3ZGQ1',
    code: 'my',
  },
  {
    name: 'Japanese Telegram',
    link: 'https://t.me/biconomyjapan',
    code: 'jp',
  },
  {
    name: 'Pakistan Telegram',
    link: 'http://t.me/Biconomy_Pakistan',
    code: 'pk',
  },
  {
    name: 'Philippines Telegram',
    link: 'http://t.me/BiconomyPhilippine',
    code: 'ph',
  },
  {
    name: 'Italian Telegram',
    link: 'http://t.me/BiconomyItalian',
    code: 'it',
  },
  {
    name: 'Iranian Telegram',
    link: 'https://t.me/biconomy_iran',
    code: 'ir',
  },
  {
    name: 'Sweden Telegram',
    link: 'https://t.me/biconomySwedish',
    code: 'se',
  },
];
