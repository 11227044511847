import futuresSocket from 'utils/futures/sockjs-client';

const socket = new futuresSocket();

export function disconnect() {
  socket.disconnect();
}

export function subscribePrice(callback) {
  return socket.subscribe('/topic/swap/thumb', (msg) => {
    callback(msg);
  });
}

export function unsubscribePrice() {
  return socket.unsubscribe('/topic/swap/thumb');
}
