import Stomp from 'stompjs';
import SockJS from 'sockjs-client/dist/sockjs.min.js';
import config from 'config/config';

class futuresSocket {
  url = '';

  pro = null;
  socketClient = null;

  connected = false;

  subscribeList = new Map();
  unSubscribeIdList = new Map();

  constructor(url) {
    this.url = url;
  }

  connect() {
    if (this.pro === null) {
      const that = this;
      this.pro = new Promise((res, rej) => {
        const socket = new SockJS(config.futuresWs);
        const socketClient = Stomp.over(socket);
        socketClient.debug = false;
        socketClient.connect(
          {},
          function (frame) {
            res();
            that.connected = true;
          },
          function (...arg) {
            that.reconnect();
            that.connected = false;
          },
        );

        this.socketClient = socketClient;
      });
    }

    return this.pro;
  }

  disconnect() {
    if (this.connected && this.socketClient) {
      this.subscribeList.clear();
      this.unSubscribeIdList.clear();
      this.socketClient.disconnect();
      this.connected = false;
    }
  }

  // 重连
  reconnect() {
    this.subscribeList.forEach((callback, url) => {
      this.subscribe(url, callback);
    });
  }

  subscribe(url, callback) {
    this.subscribeList.set(url, callback);
    return this.connect().then(() => {
      const res = this.socketClient.subscribe(url, (msg) => {
        callback(JSON.parse(msg.body));
      });

      if (res.id) {
        this.unSubscribeIdList.set(url, res.id);
      }
    });
  }

  unsubscribe(url) {
    if (this.connected && this.socketClient) {
      const id = this.unSubscribeIdList.get(url);

      if (id) {
        this.socketClient.unsubscribe(id);
      }
    }
  }
}

export default futuresSocket;
