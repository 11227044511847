/**
 * http: axios
 */
import axios from 'axios';
import config from 'config/config';
import { AuthToken } from 'data/api/auth-token';
import { browserLang, LangStore } from 'extensions/i18n';
import { convertToFormData, getUrl } from 'utils/RequestDeal';
import store from 'data/store'
import { throttle } from 'throttle-debounce';
import Vue from 'vue'

const debounceSnackBarError = throttle(
	1000,
	(msg) => {
		Vue.prototype.snackBar.error(msg)
	}
);

const baseResponseDeal = (res) => {
  const { data, status, config: conf } = res;
  const { message, msg, code, result } = data || {};
  if (data) {
    if (data.code !== 0) {
      const error = new Error(message || msg);
      error.statusCode = status;
      error.resCode = code;
      error.config = conf;

      if (data.code === 11011) {
        debounceSnackBarError(error)
        setTimeout(() => {
          store.dispatch('user/logout')
        }, 1000);
        throw ''
      } else {
        throw error;
      }
    } else {
      return result;
    }
  }
  return res;
};

const pagsmileResponseDeal = (res) => {
  const { data, status, config: conf } = res;
  const { message, msg, code, data: responseData } = data || {};
  if (data) {
    if (data.code !== '10000') {
      const error = new Error(message || msg);
      error.statusCode = status;
      error.resCode = code;
      error.config = conf;
      throw error;
    } else {
      return responseData;
    }
  }
  return res;
};

const responseDeal = (res) => {
  // pagsmile
  if (res.config.url.includes('gateway.luxpag')) {
    return pagsmileResponseDeal(res);
  }

  return baseResponseDeal(res);
};

const http = axios.create({
  baseURL: config.backendUrl,
  validateStatus: (status) => (status >= 200 && status < 300) || status >= 400,
  headers: {
    'X-SITE-ID':
      typeof window !== 'undefined' && window.INIT_STATE && window.INIT_STATE.siteInfo
        ? window.INIT_STATE.siteInfo.id || config.siteId
        : config.siteId,
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

http.interceptors.request.use(
  (config) => {
    const token = AuthToken.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    let lang = LangStore.getLang() || browserLang || 'en';

    config.headers.lang = lang;
    if (config.url === '/login') {
      config.headers['Api-Version'] = 1001
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => {
    return responseDeal(response);
  },
  (error) => {
    throw error;
  },
);

function get(url, params, header) {
  return new Promise((resolve, reject) => {
    http({
      method: 'get',
      url,
      params,
      header,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getOTC(url, data) {
  return get(config.backendOTCUrl + url, data);
}

function post(url, data, headers) {
  return new Promise((resolve, reject) => {
    http({
      method: 'post',
      url,
      data,
      headers,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function postForm(url, data) {
  const formData = convertToFormData(data);
  return post(url, formData, {
    'Content-Type': 'multipart/form-data',
  });
}

export { get, getOTC, post, postForm };
