<template>
  <div class="header-wrapper">
    <header ref="header" class="client-header header" :class="{ dark: dark }">
      <RouterLink to="/">
        <img class="logo" src="@/assets/logo.png" alt="Biconomy ｜ Exchange the Economy" />
      </RouterLink>

      <template  v-if="showHeader">
        <Nav @hook:updated="updated" v-show="!mobileHeader" />

        <div class="header-right">
          <HeaderSearch />
          <!-- <template>
            <a
              class="header-icon-wrap twitter"
              href="https://twitter.com/BiconomyCom"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                class="header-icon"
                width="0.24rem"
                height="0.24rem"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.856 3.51338C22.912 3.33727 22.7129 3.1976 22.5569 3.2966C21.7372 3.8167 20.851 4.22534 19.9218 4.51117C19.8842 4.52273 19.8435 4.51082 19.8172 4.48152C19.2201 3.81459 18.4364 3.34144 17.567 3.12391C16.6767 2.90115 15.7395 2.95718 14.8821 3.28444C14.0247 3.6117 13.2884 4.19439 12.773 4.9537C12.2575 5.71302 11.9877 6.61232 12 7.52998V8.43254C12 8.48676 11.9568 8.53116 11.9026 8.53207C10.1783 8.56101 8.47225 8.17119 6.93101 7.39543C5.51872 6.68458 4.28426 5.66951 3.31513 4.42582C3.17181 4.24189 2.88901 4.2695 2.81188 4.48955C2.14053 6.40488 0.285713 13.4553 7.83196 16.924C7.90323 16.9568 7.9159 17.0569 7.85033 17.0999C6.17435 18.2001 4.24786 18.8454 2.2597 18.9819C1.94448 19.0035 1.83888 19.4381 2.12626 19.5694C10.8913 23.5752 21 18.5243 21 7.49998C20.9991 7.23925 20.9756 6.97911 20.9297 6.72251C20.9238 6.68965 20.9343 6.65593 20.9579 6.63233C21.8325 5.75778 22.4825 4.68761 22.856 3.51338Z"
                  fill="currentColor"
                />
              </svg>
            </a>

            <Telegram>
              <div class="header-icon-wrap">
                <svg
                  class="header-icon"
                  width="0.24rem"
                  height="0.24rem"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.7097 3.6545C20.7097 3.6545 22.6523 2.897 22.4904 4.73664C22.4365 5.49415 21.9508 8.1454 21.573 11.0131L20.278 19.5079C20.278 19.5079 20.17 20.7524 19.1987 20.9688C18.2274 21.1852 16.7704 20.2113 16.5005 19.9949C16.2847 19.8326 12.4534 17.3977 11.1043 16.2074C10.7265 15.8827 10.2948 15.2334 11.1582 14.476L16.8244 9.06525C17.4719 8.41595 18.1194 6.90095 15.4213 8.7406L7.86655 13.8808C7.86655 13.8808 7.00315 14.4219 5.3843 13.9349L1.87671 12.8527C1.87671 12.8527 0.581605 12.0412 2.79408 11.2295C8.19035 8.68645 14.8278 6.0893 20.7097 3.6545Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </Telegram>
          </template> -->

          <User>
            <div class="header-icon-wrap" @click="userSideShow = true">
              <img class="avatar" :src="require('assets/header/avatar_dark.svg')" alt="" />
            </div>
          </User>

          <Lang v-if="!mobileHeader" />
          <Download v-show="!mobileHeader" />

          <div class="header-icon-wrap side-header" v-if="mobileHeader" @click="sideNavShow = true">
            <svg
              width="0.3rem"
              height="0.3rem"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="header-icon"
            >
              <path
                d="M7 9H23"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 15.0254H23"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 21H23"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <UserSide v-if="user.id" v-model="userSideShow" />

          <SideNav v-model="sideNavShow" />
        </div>
      </template>
    </header>
  </div>
</template>

<script>
import Nav from './nav.vue';
import Download from './download.vue';
import Lang from './lang.vue';
import User from './user/user.vue';
import UserSide from './user/user-side.vue';
import SideNav from './side-nav.vue';
import Telegram from '../TelegramList.vue';
import HeaderSearch from './search/header-search.vue'
import { mapActions, mapMutations, mapState } from 'vuex';
import { throttle } from 'throttle-debounce';

// 页头
export default {
  name: 'ClientHeader',
  props: {
    show: Boolean
  },
  components: {
    Nav,
    Download,
    Lang,
    User,
    UserSide,
    SideNav,
    Telegram,
    HeaderSearch
  },
  data() {
    return {
      headerWidth: 0,
      sideNavShow: false,
      userSideShow: false
    };
  },
  computed: {
    ...mapState('user', {
      user: (state) => state.info
    }),
    ...mapState('header', ['mobileHeader', 'showHeader']),
    dark() {
      return this.$route.path === '/';
    }
  },
  watch: {
    show: {
      handler(val) {
        if (!val) {
          this.sideNavShow = false;
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('header', ['getHeaders']),
    ...mapMutations('header', ['setMobileHeader']),
    windowResize() {
      if (!this.show || !this.$refs.header) return;

      // 15为滚动条宽度
      const width = document.body.offsetWidth + 15;
      const headerWidth = this.$refs.header.scrollWidth;
      if (!this.mobileHeader && headerWidth > width) {
        this.headerWidth = headerWidth;

        this.setMobileHeader(true);
      }

      if (this.mobileHeader && this.headerWidth <= width) {
        this.headerWidth = 0;
        this.setMobileHeader(false);
      }
    },
    updated() {
      this.$nextTick(() => {
        setTimeout(() => {
          const width = document.body.offsetWidth + 15;
          const headerWidth = this.$refs.header.scrollWidth;
          if (!this.mobileHeader && headerWidth > width) {
            this.headerWidth = headerWidth;
            this.setMobileHeader(true);
          }
        }, 0);
      })
    }
  },
  created() {
    this.getHeaders({ lang: this.$lang.getLang() });
  },
  mounted() {
    window.addEventListener('resize', throttle(100, this.windowResize));
  },
  beforeDestroy() {
    window.removeEventListener('resize', throttle(100, this.windowResize));
  }
};
</script>
