export function getAppToken() {
  let tokenInfo

  // 安卓
  if (window.JsInterface && window.JsInterface.webBridge) {
    const data = window.JsInterface.webBridge('token', '');
    tokenInfo = JSON.parse(data)
  } else if (window.WebInterface && window.WebInterface.webBridge) {
    const data = window.WebInterface.webBridge('token', '');
    tokenInfo = JSON.parse(data)
  } else if (window.BiconomyToken) {
    tokenInfo = window.BiconomyToken()
  }

  return tokenInfo
}

export function toAppPage(router, params) {
  let data = {
    router,
    params
  }

  const type = 'toPage'

  if (window.WebInterface?.webBridge) {
    window.WebInterface.webBridge(type, JSON.stringify(data));
  } else if (window.webkit?.messageHandlers?.webBridge) {
    const jsonData = JSON.stringify({
      type,
      data
    })
    window.webkit.messageHandlers.webBridge.postMessage(jsonData)
  }
}
