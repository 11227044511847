<template>
  <Popover 
    :referenceArrow="false" 
    placement="bottom-end"
    popperClass="header-search-popper"
    trigger="manual"
    v-model="visible"
    :offset="15"
  >
    <Input @focus="inputFocus" @clear="inputFocus" v-model="inputVal" :placeholder="$t('other_search')" clearable>
      <template #prefix>
        <SvgIcon name="search" />
      </template>
    </Input>

    <template slot="popper">
      <Scrollbar maxHeight="6.3rem" always>
        <div class="search-wrapper">

          <div v-if="inputVal">
            <p class="trade-title">{{ $t('trade') }}</p>

            <template v-if="searchList.length">
              <div 
                v-for="item in searchList"
                :key="item.symbol" 
                class="search-item" 
                @click="toTradePage(item, true)"
              >
                <div class="search-item-info">
                  <p v-html="getSearchSymbolDom(item.symbol2)"></p>
                  <p class="search-item-info-type">{{ item.showType }}</p>
                </div>

                <p class="search-item-price">{{ item.price }}</p>

                <div class="search-item-right">
                  <p :class="getColor(item.rate)">{{ numTrim(item.rate, 2) }}%</p>
                  <SvgIcon name="star" :class="{ 'is_active': item.isCustom }" @click.stop="toggleCustom(item)" />
                </div>
              </div>
            </template>

            <no-result v-else></no-result>
          </div>

          <template v-else>
            <div class="search-history" v-if="historyList.length">
              <div class="search-history-head">
                <p>{{ $t('history') }}</p>
                <p @click="clear">{{ $t('clear') }}</p>
              </div>

              <Space :size="[14, 8]" class="search-history-list">
                <template v-for="item in historyList">
                  <div 
                    class="search-history-item" 
                    :key="item"
                    @click="historyItemClick(item)"
                  >
                    {{ item }}
                  </div>
                </template>
              </Space>
            </div>

            <div class="search-history-tabs">
              <div 
                class="search-history-tabs-item"
                :class="{ 'is_active': type === 'search' }"
                @click="type = 'search'"
              >
                {{ $t('top_searches') }}
              </div>

              <div 
                class="search-history-tabs-item"
                :class="{ 'is_active': type === 'events' }"
                @click="type = 'events'"
              >
                {{ $t('hot_events') }}
              </div>
            </div>

            <div class="top-search-wrapper" v-if="type === 'search'">
              <div 
                class="top-search-item"
                v-for="(item, i) in topList" 
                :key="item.symbol"
                 @click="toTradePage(item)"
              >
                <p class="rank">{{ i + 1 }}.</p>
                <div class="top-search-item-info">
                  <p>{{ item.symbol2 }}</p>
                  <p class="top-search-item-info-type">
                    {{ item.showType }}
 
                    
                    <template v-if="i < 3">
                      <SvgIcon name="hot" v-for="k in (3 - i)" :key="k" />
                    </template>
                  </p>
                </div>

                <p class="top-search-item-price">{{ item.price }}</p>

                <div class="top-search-item-right">
                  <p :class="getColor(item.rate)">{{ numTrim(item.rate, 2) }}%</p>
                  <SvgIcon name="star" :class="{ 'is_active': item.isCustom }" @click.stop="toggleCustom(item)" />
                </div>
              </div>
            </div>

            <div class="hot-events" v-if="type === 'events'">
              <a 
                class="hot-events-item" 
                v-for="(item, i) in hotEventList" 
                :key="item.id"
                :href="item.url"
                target="_blank"
              >
                <p class="rank">{{ i + 1 }}.</p>

                <div class="hot-events-item-content">
                  <p class="title">{{ item.title }}</p>
                  <p class="desc">{{ item.sub_title }}</p>
                </div>

                <SvgIcon name="arrow-right" />
              </a>
            </div>
          </template> 
        </div>
      </Scrollbar>
    </template>
  </Popover>
</template>

<script>
import Popover from 'components/common/popover'
import { getTopSearch, setTopSearch, getHotEvent } from 'data/api/common'
import StoreData from 'data/storage'
import FuturesStore from 'data/storage/futures'
import { mapGetters, mapState, mapActions } from 'vuex'
import { CustomCoinBase } from 'utils/CustomCoinBase';

export default {
  components: {
    Popover
  },
  data() {
    return {
      visible: false,
      inputVal: '',
      type: 'search',
      historyList: StoreData.getHeaderSearchRecords(),
      topSearchList: [],
      hotEventList: [],

      futuresCustomList: []
    }
  },
  computed: {
    ...mapState('market', ['symbols']),
    ...mapState('futures', ['futuresSymbols']),
    ...mapGetters('market', ['symbolsArr']),
    ...mapGetters('futures', ['futuresSymbolList']),
    // symbol 传值， symbol2 显示
    searchList() {
      const searchVal = this.inputVal.toUpperCase()

      const futures = this.futuresSymbolList
        .filter(item => item.symbol.includes(searchVal))
        .map(item => ({
          id: item.contractCoin.id,
          type: 'futures',
          showType: this.$t('Futures'),
          symbol: item.symbol2,
          symbol2: item.symbol,
          coin: item.contractCoin.coinSymbol,
          price: this.c2ThousandsCount(this.numTrim(item.close, item.precision.price)),
          rate: item.chg * 100,
          isCustom: this.futuresCustomList.includes(item.contractCoin.id)
        }))

      const spot = this.symbolsArr
        .filter(item => item.symbol1.includes(searchVal))
        .map(item => ({
          id: item.ID,
          type: 'spot',
          showType: this.$t('spot'),
          symbol: item.symbol,
          symbol2: item.symbol1,
          coin: item.base_asset,
          price: this.c2ThousandsCount(this.numTrim(item.last, item.precision.price)),
          rate: item.rate,
          isCustom: item.tableSelected
        }))

      return futures.concat(spot)
    },
    topList() {
      return this.topSearchList.map(item => {
        return {
          symbol: item.symbol,
          type: item.type,
          showType: item.type === 'spot' ? this.$t('spot') : this.$t('Futures'),
          ...this.getTopSearchSymbolData(item)
        }
      })
    }
  },
  watch: {
    '$route'() {
      this.visible = false
    }
  },
  methods: {
    ...mapActions('futures', ['getFuturesSymbols']),
    ...mapActions('market', ['getSymbols', 'listenPrices', 'updateSymbol']),
    inputFocus() {
      setTimeout(() => {
        this.visible = true 
      });
    },
    clear() {
      StoreData.clearHeaderSearchRecords()
      this.historyList = []
      this.inputVal = ''
    },
    historyItemClick(val) {
      this.inputVal = val

      StoreData.setHeaderSearchRecords(val)
      this.historyList = StoreData.getHeaderSearchRecords()
    },
    getSearchSymbolDom(symbol) {
      const searchVal = this.inputVal.toUpperCase()

      return symbol.replace(searchVal, `<span>${searchVal}</span>`)
    },
    toTradePage(data, isSearch = false) {
      if (isSearch) {
        StoreData.setHeaderSearchRecords(data.coin)
        this.historyList = StoreData.getHeaderSearchRecords()

        setTopSearch({
          symbol: data.symbol,
          type: data.type
        })
      }

      this.inputVal = ''

      if (data.type === 'spot') {
        window.open(`/exchange/${data.symbol}`)
      } else  {
        window.open(`/futures/${data.symbol2}`)
      }
    },
    // topSearch
    getTopSearchSymbolData(data) {
      let obj = {
        symbol2: '',
        coin: '',
        price: '--',
        rate: 0,
        isCustom: false
      }

      if (data.type === 'spot') {
        const symbolData = this.symbols[data.symbol]
        if (symbolData) {

          obj = {
            id: symbolData.ID,
            symbol2: symbolData.symbol1,
            coin: symbolData.base_asset,
            price: this.c2ThousandsCount(this.numTrim(symbolData.last, symbolData.precision.price)),
            rate: symbolData.rate,
            isCustom: symbolData.tableSelected
          }
        }
      } else {
        const symbolData = this.futuresSymbols[data.symbol.replace('/', '')]
        if (symbolData) {

          obj = {
            id: symbolData.contractCoin.id,
            symbol2: symbolData.symbol,
            coin: symbolData.contractCoin.coinSymbol,
            price: this.c2ThousandsCount(this.numTrim(symbolData.close, symbolData.precision.price)),
            rate: symbolData.chg * 100,
            isCustom: this.futuresCustomList.includes(symbolData.contractCoin.id)
          }
        }
      }

      return obj
    },
    toggleCustom(data) {
      if (data.type === 'spot') {
        (data.isCustom ? CustomCoinBase.delCustom(data.id) : CustomCoinBase.addCustom(data.id))
          .then((res) => {
            this.updateSymbol({
              symbol: data.symbol,
              tableSelected: data.isCustom ? '' : 'checked'
            });
          })
      } else {
        data.isCustom ? FuturesStore.delFuturesFavorites(data.id) : FuturesStore.addFuturesFavorites(data.id)
        this.futuresCustomList = FuturesStore.getFuturesFavorites()
      }
    }
  },
  created() {
    getTopSearch().then(({ data }) => {
      this.topSearchList = data || []
    })
    
    getHotEvent().then(({ data }) => {
      this.hotEventList = data || []
    })

    this.getFuturesSymbols()
    this.getSymbols().then(() => {
      this.listenPrices()
    })

    CustomCoinBase.getCustom()

    this.futuresCustomList = FuturesStore.getFuturesFavorites()
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>

<style lang="scss">
.header-search-popper {
  z-index: $header-search-popper-index;
}
</style>