import { getWalletList, getSymbols } from 'data/api/futures';
import { subscribePrice, unsubscribePrice, disconnect } from 'data/api/futures-socket';
import { getFuturesPrecision } from 'utils/GetPrecision';
import { bigNum, numTrim } from 'utils/big'
import { getBuyPl, getSellPl, getCloseFee } from 'utils/futures/calculate'
import Vue from 'vue';
import Singleton from 'utils/Singleton';

const updatePriceMap = new Map();
let updatePriceTimer = null;

const state = () => ({
  futuresSymbols: {},
  futuresWalletList: [],
  futuresBalance: 0,
  futuresFrozenBalance: 0,
  userCouponAmount: 0,
  transferCouponStatus: false
});

const getters = {
  futuresSymbolList(state) {
    return Object.values(state.futuresSymbols);
  },
  futuresWallet(state) {
    return state.futuresWalletList.reduce((pre, item) => {
      pre[item.symbol] = item;

      return pre;
    }, {});
  },
  futuresTotalPl(state, _, __, getters) {
    const pl = state.futuresWalletList.reduce((pre, data) => {
      const price = getters['futures/getClosePrice'](data.symbol)
      
      if (data.usdtBuyPosition > 0 || data.usdtFrozenBuyPosition > 0) {
        pre = pre.plus(getBuyPl(data, price));
      }

      if (data.usdtSellPosition > 0 || data.usdtFrozenSellPosition > 0) {
        pre = pre.plus(getSellPl(data, price));
      }

      return pre
    }, bigNum(0))

    return pl.toFixed();
  },
  getClosePrice(state) {
    return (symbol) => {
      if (state.futuresSymbols[symbol]) {
        return state.futuresSymbols[symbol].close;
      } else {
        return '0';
      }
    }
  },
  walletBalance(state) {
    const balance = state.futuresWalletList.reduce((pre, item) => {
      const closeLongFee = getCloseFee({
        position: item.usdtBuyPosition,
        frozenPosition: item.usdtFrozenBuyPosition,
        shareNumber: item.usdtShareNumber,
        closeFee: item.contractCoin.closeFee
      });

      const closeShortFee = getCloseFee({
        position: item.usdtSellPosition,
        frozenPosition: item.usdtFrozenSellPosition,
        shareNumber: item.usdtShareNumber,
        closeFee: item.contractCoin.closeFee
      });

      return pre
        .plus(item.usdtBuyPrincipalAmount)
        .plus(item.usdtSellPrincipalAmount)
        .minus(closeLongFee)
        .minus(closeShortFee);
    }, bigNum(state.futuresBalance).plus(state.futuresFrozenBalance));

    return balance.toFixed();
  }
};

const mutations = {
  setFuturesSymbols(_state, data) {
    _state.futuresSymbols = data;
  },
  updateFuturesSymbols(_state, data) {
    const oldData = _state.futuresSymbols[data.symbol];
    if (oldData) {
      const markPrice = data.markPrice || oldData.markPrice;
      data.markPrice = markPrice;

      Vue.set(_state.futuresSymbols, data.symbol, {
        ...oldData,
        ...data,
      });
    }
  },
  updateFuturesWalletList(_state, data) {
    let balanceData, totalCouponAmount = bigNum(0)
    _state.futuresWalletList = data.map((item) => {
      if (item.contractCoin.id === 0) {
        balanceData = item

        totalCouponAmount = totalCouponAmount.plus(item.couponAmount)
      } else {
        totalCouponAmount = totalCouponAmount.plus(item.buyCouponAmount).plus(item.sellCouponAmount)
      }

      return {
        ...item,
        symbol: item.contractCoin.coinSymbol + item.contractCoin.baseSymbol,
        precision: getFuturesPrecision(item.contractCoin),
      }
    });

    if (totalCouponAmount.toString() !== '0') {
      _state.transferCouponStatus = true
    }

    if (balanceData) {
      _state.futuresBalance = balanceData.usdtBalance
      _state.futuresFrozenBalance = balanceData.usdtFrozenBalance
      _state.userCouponAmount = Number(balanceData.couponAmount)
    }
  },
};

const actions = {
  getFuturesSymbols({ commit, dispatch }) {
    return Singleton.promise(function() {
      return getSymbols().then((res) => {
        const data = res || [];
        const obj = {};
  
        data.forEach((item) => {
          const symbol = item.contractCoin.coinSymbol + item.contractCoin.baseSymbol;
          obj[symbol] = {
            ...item,
            symbol2: item.symbol,
            symbol,
            markPrice: item.contractCoin.markPrice,
            precision: getFuturesPrecision(item.contractCoin),
          };
        });
  
        commit('setFuturesSymbols', obj);
        dispatch('subscribePrice')
  
        return obj;
      })
    }, 'getFuturesSymbols');
  },
  getWalletList({ commit }) {
    return Singleton.promise(function() {
      return getWalletList().then((res) => {
        commit('updateFuturesWalletList', res || []);
      })
    }, 'getSitgetWalletListeInfo');
  },
  subscribePrice({ commit }) {
    subscribePrice((data) => {
      const symbol = data.symbol.replace('/', '');
      data.symbol = symbol;
      updatePriceMap.set(symbol, data);

      if (!updatePriceTimer) {
        updatePriceTimer = setInterval(() => {
          updatePriceMap.forEach((item) => {
            commit('updateFuturesSymbols', item);
          });
        }, 1500);
      }
    });
  },
  unsubscribePrice() {
    unsubscribePrice();
  },
  disconnect() {
    disconnect();
    updatePriceMap.clear();
    if (updatePriceTimer) {
      clearInterval(updatePriceTimer);
    }
  },
};

export const futures = {
  namespaced: true,
  state: state(),
  getters,
  mutations,
  actions,
};
