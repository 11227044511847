import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      hide: false
    }
  },
  computed: {
    ...mapState('user', ['assets', 'earnOverview', 'walletHide']),
    ...mapGetters('futures', ['futuresTotalPl', 'walletBalance']),
    btcPrecision() {
      return this.assets['BTC'] ? this.assets['BTC'].precision : 6;
    },
    spotFiatValue() {
      let totalValue = this.bigNum(0);
  
      for (let item in this.assets) {
        const total = this.bigNum(this.assets[item].available)
          .plus(this.assets[item].freeze)
          .plus(this.assets[item].other_freeze)
          .toFixed();
  
        if (Number(total) !== 0) {
          const value = this.$convertFiatValue(total, this.assets[item].asset_code);
          totalValue = totalValue.plus(value || 0);
        }
      }
  
      return totalValue.toFixed();
    },
    spotBtcValue() {
      return this.$fiatConvertBtc(this.spotFiatValue);
    },
    futuresFiatValue() {
      const value = this.bigNum(this.walletBalance).plus(this.futuresTotalPl).toFixed();
      return this.$convertFiatValue(value, 'USDT')
    },
    futuresBtcValue() {
      return this.$fiatConvertBtc(this.futuresFiatValue);
    },
    earnValue() {
      return this.$convertFiatValue(this.earnOverview.total_deposited, 'USDT')
    },
    earnBtc() {
      return this.$fiatConvertBtc(this.earnOverview.total_deposited)
    },
    totalFiatValue() {
      const total = this.bigNum(this.spotFiatValue).plus(this.futuresFiatValue).plus(this.earnValue).toFixed();
      return this.showFiatValue(total)
    },
    totalBtc() {
      const total = this.bigNum(this.spotBtcValue).plus(this.futuresBtcValue).plus(this.earnBtc).toFixed();
      return this.showBtcValue(total)
    }
  },
  methods: {
    ...mapMutations('user', ['setWalletHide']),
    showFiatValue(value) {
      return this.c2ThousandsCount(this.numTrim(value, 2, false))
    },
    showBtcValue(value) {
      return this.c2ThousandsCount(this.numTrim(value, this.btcPrecision, false))
    },
    switchWalletHide() {
      this.setWalletHide(!this.walletHide)
    }
  },
}