<template>
  <div id="app">
    <router-view />
    <LocaleCurrencyModal />
    <SidebarBox />
    <!-- <CountryTipModal /> -->
  </div>
</template>

<script>
import { AuthToken } from 'data/api/auth-token';
// import CountryTipModal from 'components/client/country-tip-modal'
import LocaleCurrencyModal from 'components/client/locale-currency-modal.vue'
import SidebarBox from 'components/client/sidebar-box.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { isBiconomy } from 'utils/UserAgent';

// 根路由
export default {
  name: 'app',
  components: {
    LocaleCurrencyModal,
    SidebarBox
  },
  watch: {
    theme: {
      handler(val) {
        document.body.setAttribute('data-theme', val);
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['siteInfo']),
  },
  methods: {
    ...mapActions('exchange', ['getExchangeRate']),
    ...mapActions('user', ['logout', 'getUserInfo']),
    ...mapMutations(['setIsApp'])
  },
  beforeCreate() {
    if (['ar', 'fa'].includes(this.$lang.getLang())) {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    }
  },
  created() {
    this.getExchangeRate().catch(this.snackBar.error)
    setInterval(() => {
      this.getExchangeRate()
    }, 300000);
    
    const isApp = isBiconomy()
    // const isApp = true
    this.setIsApp(isApp)
    if (isApp) {
      window.zE?.('messenger', 'hide');
    }

    const biconomy = {
      setToken: (token) => {
        AuthToken.setToken(token);
        this.getUserInfo();
      }
    };
    window.biconomy = biconomy;

    const token = AuthToken.getToken();
    if (token) {
      this.getUserInfo()
    }
  },
  mounted() {
    // token 信息变化处理
    AuthToken.tokenChange.subscribe((token, expired) => {
      if (expired) {
        // 过期
        this.snackBar.info(this.$t('token.expire'));
        setTimeout(this.logout, 1000);
      } else {
        // 浏览器其他窗口操作导致 token 信息变化
        window.location.reload();
      }
    });
  }
};
</script>
