import { getHeader } from 'data/api/header.js';

const state = () => ({
  headers: {},
  mobileHeader: false,
  showHeader: true,
});

const getters = {
  nav(state, _, { user }) {
    const data = state.headers.nav || [];

    return data.reduce((pre, item) => {
      // 如果是单独的链接
      if (!item.children) {
        if (!item.hide) {
          pre.push(item);
        }
        return pre;
      }

      if (item.type === 'more') {
        const children = [];
        item.children.forEach((h) => {
          const data = h.children.filter((k) => !k.hide);
          if (data.length) {
            children.push({
              ...h,
              children: data,
            });
          }
        });

        if (children.length) {
          pre.push({
            ...item,
            children,
          });
        }

        return pre;
      }

      const children = item.children.filter((h) => {
        if (h.type === 'coupons') {
          return user.info.agent_show === 1 && !h.hide;
        } else if (h.type === 'novice') {
          if (!user.info.id) return true
          if (!user.info.register_time) return true
          
          return user.info.register_time > Date.now() - (30 * 24 * 60 * 60 * 1000)
        }

        return !h.hide
      });
      
      if (children.length) {
        pre.push({
          ...item,
          children,
        });
      }

      return pre;
    }, []);
  },
  userLink(state) {
    const data = state.headers.userLink || [];

    return data.reduce((pre, item) => {
      const children = item.children.filter((h) => !h.hide);
      if (children.length) {
        pre.push({
          ...item,
          children,
        });
      }

      return pre;
    }, []);
  },
  userInfoLink(state, _, { user }) {
    const data = state.headers.userInfoLink || [];

    return data.filter((item) => {
      if (item.hide) {
        return false;
      } else if (item.vip) {
        return user.info.vip >= item.vip;
      }

      return true;
    });
  },
};

const mutations = {
  setHeaders(state, data) {
    state.headers = data;
  },
  setMobileHeader(state, val) {
    state.mobileHeader = val;
  },
  setShowHeader(state, val) {
    state.showHeader = val;
  },
};

const actions = {
  getHeaders({ commit }, { lang }) {
    // return Home.getHeaders().then((res) => {
    //   commit(SYSTEM.GET_HEADERS, res);
    //   return res;
    // });
    getHeader(lang).then((res) => {
      const data = res.data || [];
      commit('setHeaders', data);
    });
  },
};

export const header = {
  namespaced: true, // 增加命名空间，外部使用时需要指明命名空间（如果没有外部冲突，可以去掉）
  state: state(),
  getters,
  mutations,
  actions,
};
