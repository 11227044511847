'use strict'
module.exports = {
  // localhost: 'https://localhost:84/api/v1', // 服务器运行 server-index.js 时使用
  localhost: 'http://8.131.92.184:84/api/v1',
  backendUrl: 'http://8.131.92.184:84/api/v1',
  v4Url: 'http://8.131.92.184:7883/api/v4',
  v10Url: 'http://8.131.92.184:5883/api/v10',
  backendOTCUrl: 'https://www.biconomy.com/otc/api/v1',
  launchpadUrl:'http://8.131.92.184/api',
  activityUrl: 'http://8.131.92.184:84/api/activity',
  socketUrl: 'ws://8.131.92.184:84/ws',
  klineSocketUrl: 'ws://8.131.92.184:84/ws',
  // socketUrl: 'ws://btc.facetoface.top/',                             // 服务端 websocket 的 url
  // klineSocketUrl: 'ws://btc.facetoface.top/',                             // 服务端 k线的websocket 的 url
  // ossUrl: 'http://39.99.165.131:8886/',
  ossUrl:'/',
  
  assetsSubDirectory: 'static',
  anchorCoin: 'CNT',
  "siteId": 127,
  futureUrl: 'https://api.btc-face.com',
  futuresWs: 'https://api.btc-face.com/swap/swap-ws',
  oss: 'https://biconomyweb.oss-ap-northeast-1.aliyuncs.com',
  ossAccelerate: 'https://biconomyweb.oss-accelerate.aliyuncs.com', // oss加速
  ossDoc: 'https://biconomydoc.oss-accelerate.aliyuncs.com',
  ossId: 'https://biconomyid.oss-accelerate.aliyuncs.com'
};
