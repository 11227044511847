<template>
  <footer class="bc-page-content">
    <div class="flex">
      <img class="logo" :src="require('assets/footer_logo.png')" alt="Biconomy" />
    </div>

    <div class="footer-link-wrapper">
      <div 
        class="footer-link-list"
        :class="{ 'active': activeLink === i }"
        v-for="(item, i) in links" 
        :key="item.title"
      >
        <div class="title-wrap" @click="activeLink = activeLink === i ? -1 : i">
          <p class="title">{{ item.title }}</p>
          <SvgIcon name="arrow-down" :class="{ reverse: activeLink === i }" />
        </div>

        <div class="footer-link-wrap">
          <a 
            v-for="h in item.children" 
            :key="h.name" 
            class="footer-link-item"
            :href="h.href"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ h.name }}
          </a>
        </div>
      </div>
    </div>

    <div class="community-wrapper">
      <p>{{ $t('footer_community') }}</p>

      <Space :size="[24, 12]" :wrapItem="false">
        <template v-for="(item, i) in communityList">
          <Popover
            v-if="item.type === 'telegram'" 
            :key="i" 
            :referenceArrow="false"
            placement="top"
            trigger="hover"
          >
            <div class="community-item">
              <img :src="item.icon">
            </div>

            <template slot="popper">
              <Scrollbar height="2rem" class="telegram-list" always>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  v-for="item in telegramList"
                  :key="item.name"
                  :href="item.link"
                >
                  <img class="flag-icon" :src="getOssUrl(item.code)" />
                  {{ item.name }}</a
                >
              </Scrollbar>
            </template>
          </Popover>

          <a 
            v-else
            class="community-item"
            :key="i" 
            :href="item.link" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <img :src="item.icon">
          </a>
        </template>
      </Space>
    </div>

    <p class="copyright">
      {{ $t('footer_copyright', { data: '2019-2024' }) }}
    </p>
  </footer>
</template>

<script>
import Popover from 'components/common/popover'
import { telegramList } from 'utils/telegram-list';
import { getOssDoc } from 'utils/oss';

export default {
  name: 'Footer',
  components: {
    Popover
  },
  data() {
    return {
      telegramList,
      lang: this.$lang.getLang(),
      communityList: [
        { link: 'https://twitter.com/BiconomyCom', icon: require('assets/client/home/community/twitter.png') },
        { type: 'telegram', icon: require('assets/client/home/community/telegram.png') },
        { link: 'https://www.facebook.com/BiconomyCom', icon: require('assets/client/home/community/facebook.png') },
        { link: 'https://www.linkedin.com/company/biconomycom', icon: require('assets/client/home/community/linkedin.png') },
        { link: 'https://www.youtube.com/c/BiconomyGlobal', icon: require('assets/client/home/community/youtube.png') },
        { link: 'https://www.tiktok.com/@biconomycom?_t=8hxL7yv2ojN&_r=1', icon: require('assets/client/home/community/tiktok.png') },
        { link: 'https://www.instagram.com/biconomy/', icon: require('assets/client/home/community/ins.png') },
        { link: 'https://discord.gg/QQBZKfQnaY', icon: require('assets/client/home/community/discord.png') },
        { link: 'https://biconomycom.medium.com', icon: require('assets/client/home/community/medium.png') },
        { link: 'https://www.reddit.com/r/biconomyExchange/', icon: require('assets/client/home/community/reddit.png') },
        { link: 'https://coinmarketcap.com/exchanges/biconomy/', icon: require('assets/client/home/community/cmc.png') },
        { link: 'https://www.coingecko.com/en/exchanges/biconomy', icon: require('assets/client/home/community/coingecko.png') }
      ],
      zendeskLang: {
        'zh-hant': 'zh-hk',
        ko: 'ko',
        ru: 'ru',
        'pt-PT': 'pt-pt',
        tr: 'tr-tr',
        'es-es': 'es-es'
      },
      activeLink: -1,
    }
  },
  computed: {
    links() {
      const zendesk = this.zendeskLang[this.lang.value] || 'en-us';
      return [
        {
          title: 'Biconomy',
          children: [
            {
              name: this.$t('footer.AboutUs'),
              href: '/about',
            },
            {
              name: this.$t('Careers'),
              href: '/careers'
            },
            {
              name: this.$t('media_kit'),
              href: '/BrandKit'
            },
            {
              name: this.$t('referral_program'),
              href: '/user-center/invitation'
            },
            {
              name: this.$t('feedback_and_suggestions'),
              href: 'mailto:support@biconomy.com',
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            {
              name: this.$t('footer.bit'),
              href: '/bittoken',
            },
            {
              name: this.$t('ambassador.faqs'),
              href: `https://biconomy.zendesk.com/hc/en-us/categories/360002352932-FAQs`,
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            {
              name: this.$t('footer.Announcements'),
              href: `https://biconomy.zendesk.com/hc/${zendesk}`,
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            {
              name: this.$t('footer.Fees'),
              href: '/fee'
            },
            // 'footer.Termsofservice',
            // 'footer.PrivacyPolicy'
            // 'footer.news'
            {
              name: this.$t('terms_of'),
              href: `https://biconomy.zendesk.com/hc/en-us/articles/360036401031-Terms-of-service`,
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            {
              name: this.$t('privacy_policy'),
              href: `https://biconomy.zendesk.com/hc/en-us/articles/360036040012-Privacy-policy`,
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            {
              name: this.$t('kyc_aml'),
              href: `https://biconomy.zendesk.com/hc/en-us/articles/360036979172-Know-your-customers-and-Anti-Money-Laundering-Policies`,
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          ]
        },
        {
          title: this.$t('footer.Products'),
          children: [
            {
              name: this.$t('spot_trading'),
              href: '/exchange/BTC_USDT'
            },
            {
              name: this.$t('Futures'),
              href: '/futures/BTCUSDT'
            },
            {
              name: this.$t('biconomy_earn'),
              href: '/earn'
              // beta: true
            },
            {
              name: this.$t('crypto.title'),
              href: '/buycrypto'
            },
            {
              name: 'Launchpad',
              href: '/launchpad'
            },
            {
              name: 'DAO',
              href: '/dao'
            },
            {
              name: this.$t('Staking'),
              href: '/farms/',
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            {
              name: 'NFT',
              href: '/nft',
              // coming: true
              beta: true
            },
            {
              name: 'LIVE',
              href: '/live'
              // coming: true
              // beta: true
            }
            // 'footer.markets',
            // 'footer.exchange'
            //  'footer.buycrypto'
          ]
        },
        {
          title: this.$t('support_services'),
          children: [
            {
              name: this.$t('submit_request'),
              href: `https://biconomy.zendesk.com/hc/${zendesk}/requests/new`,
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            {
              name: this.$t('official_verification'),
              href: '/anti-fake',
            },
            {
              name: this.$t('business_contacts'),
              href: 'mailto:business@biconomy.com',
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            // 'footer.Fees',
            {
              name: this.$t('beginner_guide'),
              href: 'https://www.biconomy.com/blog',
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            {
              name: this.$t('Biconomy Blog'),
              href: 'https://www.biconomy.com/blog',
            },
            {
              name: this.$t('footer.API'),
              href: 'https://github.com/BiconomyOfficial/apidocs',
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            {
              name: this.$t('certification.title'),
              href: `https://biconomy.zendesk.com/hc/en-us/articles/4407360156569-How-to-complete-Biconomy-Identity-Verification-`,
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          ]
        },
        {
          title: this.$t('footer.business'),
          children: [
            {
              name: this.$t('token_listing'),
              href: '/business/listings'
            },
            {
              name: this.$t('listing_application'),
              href: 'https://docs.google.com/forms/d/1QVHTXUu-KwO6qBjqmmzVULtMODa5D3RazxKTi64HKyE/viewform?edit_requested=true',
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            {
              name: this.$t('launchpad_application'),
              href: 'https://docs.google.com/forms/d/e/1FAIpQLSeMqa_DW1fvj9lTEfUYTwcrX6SHFMkBN2A8Au62D8SfcKEfsQ/viewform?vc=0&c=0&w=1&flr=0',
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            {
              name: this.$t('ambassador_program'),
              href: '/ambassadorPage'
            },
            {
              name: this.$t('Futures Affiliate'),
              href: 'https://docs.google.com/forms/d/e/1FAIpQLSeqP7KQTEOy8675lav0ML9anxMIVXTExZa0r6_LZ49Tu0mbvQ/viewform?usp=sf_link',
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            {
              name: 'Labs',
              href: '/labs'
            }
          ]
        },
        // {
        //   title: 'Legal',
        //   children: [
        //     {
        //       name: 'terms_of',
        //       href: `https://biconomy.zendesk.com/hc/en-us/articles/360036401031-Terms-of-service`,
        //       target: '_blank',
        //       rel: "noopener noreferrer"
        //     },
        //     {
        //       name: 'privacy_policy',
        //       href: `https://biconomy.zendesk.com/hc/en-us/articles/360036040012-Privacy-policy`,
        //       target: '_blank',
        //       rel: "noopener noreferrer"
        //     },
        //     {
        //       name: 'kyc_aml',
        //       href: `https://biconomy.zendesk.com/hc/en-us/articles/360036979172-Know-your-customers-and-Anti-Money-Laundering-Policies`,
        //       target: '_blank',
        //       rel: "noopener noreferrer"
        //     }
        //   ]
        // },
        {
          title: this.$t('crypto.title'),
          children: [
            {
              name: 'Biconomy Exchange Token(BIT)',
              href: `/exchange/BIT_USDT`,
              target: '_blank'
            },
            {
              name: 'Bitcoin(BTC)',
              href: `/exchange/BTC_USDT`,
              target: '_blank'
            },
            // 'footer.Fees',
            {
              name: 'Ethereum(ETH)',
              href: '/exchange/ETH_USDT',
              target: '_blank'
            },
            {
              name: 'Tron(TRX)',
              href: '/exchange/TRX_USDT',
              target: '_blank'
            },
            {
              name: 'Litecoin(LTC)',
              href: '/exchange/LTC_USDT',
              target: '_blank'
            },
            {
              name: 'ApeCoin(APE)',
              href: '/exchange/APE_USDT',
              target: '_blank'
            },
            {
              name: 'Filecoin(FIL)',
              href: '/exchange/FIL_USDT',
              target: '_blank'
            },
            {
              name: 'ChainLink(LINK)',
              href: '/exchange/LINK_USDT',
              target: '_blank'
            },
            {
              name: 'Uniswap(UNI)',
              href: '/exchange/UNI_USDT',
              target: '_blank'
            },
            {
              name: 'Aptos(APT)',
              href: '/exchange/APT_USDT',
              target: '_blank'
            },
            {
              name: 'Dogecoin(DOGE)',
              href: '/exchange/DOGE_USDT',
              target: '_blank'
            },
            {
              name: 'Shiba Inu(SHIB)',
              href: '/exchange/SHIB_USDT',
              target: '_blank'
            }
          ]
        }
      ];
    },
  },
  methods: {
    getOssUrl(code) {
      return getOssDoc(`/flags_png/${code}.png`);
    }
  }
}
</script>
<style lang="scss" scoped>
.telegram-list {
  padding: 0.1rem 0.04rem;

  a {
    @include flex;
    color: inherit;
    padding: 0.04rem 0.08rem;

    &:hover {
      background-color: $gray-hover;
    }

    img {
      margin-inline-end: 0.15rem;
      width: 0.15rem;
    }
  }
}
</style>

<style lang="scss" scoped>
footer {
  padding-top: 0.8rem;
  padding-bottom: 0.32rem;

  .flex {
    @include flex(space-between, center, wrap);
    @include text-lg;
    margin-bottom: 0.56rem;

    img {
      height: 0.4rem;
      margin-inline-end: 0.12rem;
    }
  }

  .footer-link-wrapper {
    @include flex(space-between, flex-start);

    .footer-link-list {
      flex: 1;
      padding: 0.16rem 0.1rem;
      border-radius: 0.08rem;
      background: linear-gradient(180deg, rgba(217, 217, 217, 0.05) 0%, rgba(217, 217, 217, 0) 100%);

      &:not(:last-of-type) {
        margin-inline-end: 0.15rem;
      }

      .title-wrap {
        height: 0.4rem;
        @include flex(space-between);
      }

      .bc-icon-arrow-down {
        font-size: 0.24rem;
        display: none;
        transition: transform .3s;

        &.reverse {
          transform: rotate(180deg);
        }
      }

      .title {
        font-size: 0.16rem;
        font-weight: 700;
        line-height: 0.24rem;
        flex: 1;
      }

      .footer-link-wrap {
        margin-top: 0.18rem;
      }

      a {
        display: block;
        width: 100%;
        color: inherit;
        margin-bottom: 8px;

        &:hover {
          color: $main-color;
          text-decoration: underline;
        }
      }
    }
  }

  .community-wrapper {
    margin-top: 0.86rem;
    margin-bottom: 0.4rem;
    padding: 0.2rem 0.16rem;
    border-radius: 0.08rem;
    background-color: $main-color;
    @include flex(space-between, center, wrap);
    box-shadow: 0px 0.04rem 0.12rem 0px rgba(0, 0, 0, 0.35);
    @include text-2xl(600);
    color: #fff;

    .community-item {
      display: block;
      width: 0.32rem;
      height: 0.32rem;
      border-radius: 50%;
      box-shadow: 0px 0.04rem 0.12rem 0 rgba(0, 0, 0, 0.35);
      
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .copyright {
    text-align: center;
  }

  @media screen and (max-width: 992px) {
    .community-wrapper {
      justify-content: center;

      p {
        width: 100%;
        text-align: center;
        margin-bottom: 0.12rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 1rem;

    .flex {
      margin-bottom: 0.32rem;

      p {
        margin-top: 0.12rem;
        width: 100%;
      }
    }

    .footer-link-wrapper {
      flex-wrap: wrap;

      .footer-link-list {
        flex: none;
        width: 100%;
        padding: 0.1rem;

        .title-wrap {
          cursor: pointer;
        }

        .bc-icon-arrow-down {
          display: block;
        }

        &:not(.active) {
          .footer-link-wrap {
            display: none;
          }
        }
      }
    }

    .community-wrapper {
      @include text-lg2;
      margin-top: 0.5rem;

      .bc-space {
        width: 100%;
        justify-content: space-around !important;
        gap: 0.12rem !important;
      }
    }
  }
}
</style>