<template>
  <div>
    <div class="header-wallet-info">
      <div class="top">
        <p>{{ $t('AssetsManage.portfolio_value_1') }} (BTC)</p>
      
        <SvgIcon class="eye-icon" v-if="walletHide" name="eye-invisible" @click="switchWalletHide" />
        <SvgIcon class="eye-icon" v-else name="eye" @click="switchWalletHide" />
      </div>

      <template v-if="walletHide">
        <p class="btc-value">******</p>

        <p class="fiat-value">******</p>
      </template>

      <template v-else>
        <p class="btc-value">{{ totalBtc }}</p>

        <p class="fiat-value">≈{{ $currencyObj.unit }} {{ totalFiatValue }}</p>
      </template>
    </div>
    
    <Space :size="8" :wrapItem="false">
      <RouterLink to="/user-center/assets-manage/deposit?coin=TRC20-USDT" @click.native="$emit('clickLink')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.63604 20C7.00736 18.4099 6 16.2132 6 13.7868C6 8.93398 10.0294 5 15 5C19.9706 5 24 8.93398 24 13.7868C24 16.2132 22.9926 18.4099 21.364 20" stroke="#4D77FF" stroke-width="2.2"/>
          <path d="M11 16L15.0045 12L19 15.9911" stroke="#4D77FF" stroke-width="2.2" stroke-linejoin="round"/>
          <path d="M15 25L15 13" stroke="#4D77FF" stroke-width="2.2" stroke-linejoin="round"/>
        </svg>

        <span>{{ $t('asset.deposit') }}</span>
      </RouterLink>

      <RouterLink to="/user-center/assets-manage/withdraw" @click.native="$emit('clickLink')">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.63604 10C7.00736 11.5901 6 13.7868 6 16.2132C6 21.066 10.0294 25 15 25C19.9706 25 24 21.066 24 16.2132C24 13.7868 22.9926 11.5901 21.364 10" stroke="#4D77FF" stroke-width="2.2"/>
          <path d="M11 9L15.0045 5L19 8.99107" stroke="#4D77FF" stroke-width="2.2" stroke-linejoin="round"/>
          <path d="M15 18L15 6" stroke="#4D77FF" stroke-width="2.2" stroke-linejoin="round"/>
        </svg>

        <span>{{ $t('asset.withdraw') }}</span>
      </RouterLink>
    </Space>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import walletMixin from 'utils/mixins/wallet'

export default {
  mixins: [walletMixin],
  methods: {
    ...mapActions('user', ['getAssets', 'getEarnOverview']),
    ...mapActions('futures', ['getWalletList', 'getFuturesSymbols']),
    ...mapActions('market', ['getSymbols', 'listenPrices']),
  },
  created() {
    ['getEarnOverview', 'getAssets', 'getSymbols', 'getWalletList', 'getFuturesSymbols'].forEach(item => {
      if (item === 'getSymbols') {
        this[item]().then(() => {
          this.listenPrices()
        })
      } else {
        this[item]()
      }
    })
  }
}
</script>

<style lang="scss">
.header-nav-popover {
  .header-wallet-info {
    background-color: var(--gray2);
    margin: 0.16rem 0.16rem 0.08rem;
    border-radius: 0.08rem;
    padding: 0.16rem 0.16rem 0.14rem;
    width: 2.4rem;

    .top {
      @include flex;
      color: $gray-light;
      margin-bottom: 0.08rem;

      p {
        margin-inline-end: 0.08rem;
        @include text-sm;
      }

      .bc-svg-icon {
        font-size: 0.16rem;

        &:hover {
          color: $main-color;
          cursor: pointer;
        }
      }
    }
    
    .btc-value {
      @include text-xl;
      margin-bottom: 0.04rem;
    }

    .fiat-value {
      color: $gray-light;
      @include text-sm;
    }
  }

  .bc-space {
    margin: 0 0.16rem 0.08rem;

    a {
      @include flex(center);
      height: 0.36rem;
      background-color: var(--gray2);
      border-radius: 0.06rem;
      flex: 1;
      @include text-sm(500);
      color: inherit;

      svg {
        margin-inline-end: 0.06rem;
      }

      &:hover {
        color: $main-color;
      }
    }
  }
  
  .header-user-popover-item {
    @include flex();
    height: 0.48rem;
    white-space: nowrap;
    padding: 0 0.24rem;
    min-width: 1rem;
    color: var(--color);
    font-weight: 500;
    text-transform: capitalize;

    &:hover {
      background-color: $gray-hover;
      color: $main-color;
    }

    .name {
      position: relative;

      .new {
        position: absolute;
        top: 0;
        color: $white;
        padding: 0 0.06rem;
        background: $main-color;
        border-radius: 0.04rem;
        height: 0.16rem;
        line-height: 0.16rem;
        font-size: 0.12rem;
        @include absoluteRight(0);

        @include ltl {
          transform: translate(100%, -50%) scale(0.9);
        }

        @include rtl {
          transform: translate(-100%, -50%) scale(0.9);
        }
      }
    }
  }
}
</style>